import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Footer from '../components/Footer'
import Nav from '../components/Nav'

import './foundation.css'
import '../styles/app.scss'
// import '../styles/index.scss'
// import '../styles/motion.min.css'

const Layout = ({ fixed, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'We are an independent national movement of First Nations and multicultural migrant communities organising and campaigning for racial justice',
            },
            {
              name: 'keywords',
              content:
                'first nations, people of colour, racial justice, migrant communities',
            },
            {
              name: 'viewport',
              content:
                'width=device-width, initial-scale=1.0, viewport-fit=cover',
            },
            {
              property: 'og:title',
              content: 'Colour Code',
            },
            {
              property: 'og:description',
              content:
                'We are an independent national movement of First Nations and multicultural migrant communities organising and campaigning for racial justice',
            },
            {
              property: 'og:image',
              content:
                'https://d33wubrfki0l68.cloudfront.net/e3283aa2d633a6c6e4e3daaf881b9dae2afb8987/358c7/uploads/0g2a8850.jpg',
            },
          ]}
        >
          <html lang='en' />
          {/* <link
            href="https://fonts.googleapis.com/css?family=Inconsolata:400,700"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,700"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,600"
            rel="stylesheet"
          /> */}
          <link
            href='https://cdn.jsdelivr.net/npm/inter-ui@3.2.0/inter-ui.min.css'
            rel='stylesheet'
          />
          <link
            href='https://fonts.googleapis.com/css?family=Merriweather:300,700'
            rel='stylesheet'
          />
          <link rel='stylesheet' href='https://use.typekit.net/wyj3wpn.css' />
          {/* <link rel="stylesheet" href="https://use.typekit.net/acu8kmr.css" /> */}
          {/* <style>@import url('https://rsms.me/inter/inter-ui.css');</style> */}
        </Helmet>
        {fixed ? <Nav fixed /> : <Nav />}
        {children}
        <Footer />
      </React.Fragment>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
