import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import LoadingAnimation from '../components/LoadingAnimation.jsx'

export default class ButtonModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      donateLink: this.props.href,
    }
  }

  componentDidMount() {
    if (this.props.href) {
      this.setState({ donateLink: this.props.href })
    }

    function getURLParam(sParam) {
      var sPageURL = window.location.search.substring(1)
      var sURLVariables = sPageURL.split('&')
      var keyAndValue, indexOfEquals, key, value
      for (var i = 0; i < sURLVariables.length; i++) {
        keyAndValue = sURLVariables[i]
        indexOfEquals = keyAndValue.indexOf('=')
        if (indexOfEquals > -1) {
          key = keyAndValue.substring(0, indexOfEquals)
          value = keyAndValue.substring(indexOfEquals + 1).replace(/\//g, '')
          if (key === sParam) {
            return value
          }
        }
      }
    }
    let token = getURLParam('t')
    if (token) {
      const donateLink = this.state.donateLink + '?t=' + token
      this.setState({ donateLink })
    }
  }
  render() {
    const { className = '' } = this.props
    return (
      <Popup
        trigger={<a className={`btn ${className}`}> {this.props.buttonText}</a>}
        modal
        closeOnDocumentClick
      >
        {close => (
          <div className="modal">
            <a className="close" onClick={close}>
              &times;
            </a>
            <iframe src={this.state.donateLink} />
            <LoadingAnimation />
          </div>
        )}
      </Popup>
    )
  }
}
