import React from 'react'
import { Link } from 'gatsby'
import CCLogo from '../images/colorcode-logo.svg'
// import '../styles/nav.scss'
import ButtonModal from './ButtonModal'
import ScrollPosition from '../components/ScrollPosition'

class Nav extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isMenuActive: false,
      scrollPosition: null,
    }
  }
  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const el = document.scrollingElement || document.documentElement

    this.setState({
      scrollPosition: el.scrollTop,
    })
  }
  handleMenuClick = () => {
    this.setState({ isMenuActive: !this.state.isMenuActive })
  }
  render () {
    const donation = {
      buttonText: 'Donate',
      link:
        'https://www.getup.org.au/campaigns/climate-action-now/bushfire-donation-page/bsca-donation-page',
    }

    const fixed = this.props.fixed
    return (
      <>
        <nav
          className={
            fixed
              ? 'fixed'
              : 'sticky ' +
                (this.state.scrollPosition > 450
                  ? 'animate-in-nav'
                  : 'animate-out-nav')
          }
        >
          <div className='row align-center'>
            <div className='small-12 medium-12 large-10 columns'>
              <div className='nav-container'>
                <ul>
                  <li className='nav-logo--wrapper '>
                    <Link to='/' className='logo'>
                      <img src={CCLogo} />
                    </Link>
                  </li>
                </ul>
                <div style={{ display: 'none' }}>
                  <ul style={{ marginLeft: 'auto' }}>
                    <li>
                      <ButtonModal
                        href={donation.link}
                        buttonText={donation.buttonText}
                      />
                    </li>
                  </ul>
                </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`mobile-menu-container  ${
              this.state.isMenuActive ? 'is-active' : ''
            }`}
          >
            <div
              className={`mobile-menu ${
                this.state.isMenuActive ? 'is-active' : ''
              }`}
            >
              <ul>
                <li>
                  <Link to='/bushfires-and-climate-change'>Climate Change</Link>
                </li>
                <li>
                  <Link to='/about'>Survivors’ Stories</Link>
                </li>
                <li>
                  <Link to='/support-us'>Support Us</Link>
                </li>
                <li>
                  <Link to='/media'>Media</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
    )
  }
}

export default Nav
