import { Link } from 'gatsby'
import React from 'react'
// import '../styles/footer.scss'
import CCLogo from '../images/colorcode-logo.svg'

const Footer = () => (
  <footer>
    <div className='footer-wrapper pt-3'>
      <ul>
        <img src={CCLogo} style={{ maxWidth: '150px' }} />
      </ul>
      <ul>
        <li className='footer-col-header'>
          <Link to='/about'>Info</Link>
        </li>

        <li>
          <Link to='/about'>About</Link>
        </li>
        <li>
          <Link to='/contact'>Contact</Link>
        </li>
        <li>
          <Link to='/privacy-policy'>Privacy Policy</Link>
        </li>
      </ul>
      <ul>
        <li className='footer-col-header'>
          <a href="/donate">Donate</a>
        </li>
        <li>
          <a href="/donate">Make a Donation</a>
        </li>
        {/* <li>
          <a href="https://getup.org.au/donate/willpower/">Gift in your Will</a>
        </li> */}
        <li>
          <Link to='/donations-policy'>Donations Policy</Link>
        </li>
        {/* <li>
          <a href="https://getup.org.au/donations-disclosure">
            Donations Disclosure
          </a>
        </li> */}
      </ul>
      <ul>
        <li className='footer-col-header'>
          <a
            href='https://www.facebook.com/colourcodeorgau'
            rel='noopener'
            target='_blank'
          >
            Social
          </a>
        </li>
        <li>
          <a
            href='https://www.facebook.com/colourcodeorgau'
            rel='noopener'
            target='_blank'
          >
            Facebook
          </a>
        </li>
        <li>
          <a
            href='https://twitter.com/colourcodeorgau'
            rel='noopener'
            target='_blank'
          >
            Twitter
          </a>
        </li>
        <li>
          <a
            href='https://www.youtube.com/channel/UC_2HFvonsrCA6K3cRDI0iFQ'
            rel='noopener'
            target='_blank'
          >
            YouTube
          </a>
        </li>
        <li>
          <a
            href='https://www.instagram.com/colourcodeorgau/'
            rel='noopener'
            target='_blank'
          >
            Instagram
          </a>
        </li>
      </ul>

      <div className='acknowledgement mt-0'>
        <p>
          Our team acknowledges that we meet and work on the land of the Gadigal
          people of the Eora Nation. We wish to pay respect to their Elders —
          past, present and future — and acknowledge the important role all
          Aboriginal and Torres Strait Islander people continue to play within
          Australia.
        </p>
        <p>
          WARNING: Aboriginal and Torres Strait Islander people are warned that
          this website may contain images or names of deceased persons.
        </p>
        <p>Authorised by Z. Edries, GetUp for Colour Code, Sydney.</p>
      </div>
    </div>
  </footer>
)

export default Footer
