import React, { Component } from 'react'

export default class ScrollPosition extends Component {
  state = {
    position: null,
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
  handleScroll = event => {
    const scrollTop = event.srcElement.body.scrollTop
    console.log(event)
    this.setState({
      position: scrollTop,
    })
  }
  render() {
    return <div>{this.props.children(this.state.position)}</div>
  }
}
