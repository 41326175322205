import React from 'react'
import { withPrefix } from 'gatsby'
import Spinner from '../images/spinner.svg'

const LoadingAnimation = () => (
  <div className="loading-image-container">
    <img src={Spinner} />
  </div>
)

export default LoadingAnimation
